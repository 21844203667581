import React from 'react'
import { Card } from 'react-bootstrap'

function GpioOverviewCOmponent({gpio}) {
  return (

    <Card >
      <Card.Body>
        <h5>GPIO overview</h5>
        <br />
        <div className='w-100 d-flex flex-wrap justify-content-evenly'>
          {gpio.map((io) => {return(
            <Card style={{width: '45%', textAlign: 'left', paddingLeft: '10px', backgroundColor: io.state  ? 'green' : 'red'}}>
                <strong>{io.Gpio}</strong>
                <p>{io.state ? 'On' : 'Off'}</p>
            </Card>
          )})}
        </div>
      </Card.Body>
    </Card>

  )
}

export default GpioOverviewCOmponent