import React, {useState} from 'react'
import { Button, Modal, Form} from 'react-bootstrap'

function SystemsModal({showModal, hideModal, save, companies}) {
  const [name, setName] = useState('')
  const [company, setCompany] = useState(0);


  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{'New system'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Label>Name</Form.Label>
            <Form.Control value={name} onChange={e => setName(e.target.value)}/>
          </div>
          <br />
          <div>
            <Form.Label>Company</Form.Label>
            <Form.Select value={company} onChange={e => setCompany(e.target.value)}>
              <option value="0">Select company</option>
              {companies.map((company, index) => {return(
                <option key={company.id} value={company.id}>{company.name}</option>
              )})}
            </Form.Select>
            <br />
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>Cancel</Button>
          <Button onClick={e => save({})}>Save</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default SystemsModal