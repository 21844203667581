import React, {useEffect, useState} from 'react'
import {Card, Button, Spinner} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux'

import UsersModal from '../../modals/UsersModal';
import DeleteModal from '../../modals/DeleteModal';
import {GetUsers, CreateUser, UpdateUser, DeleteUser} from '../../../actions/UsersAction';

function UsersComponent() {
  const dispatch = useDispatch();
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [roles] = useState({0: 'Admin', 1: 'Supervisor', 2: 'User'})
  const currentUser = useSelector(state => state.SessionReducer);
  const users = useSelector(state => state.UsersReducer);
  const companies = useSelector(state => state.CompaniesReducer);

  useEffect(() => {
    if(users.length === 0 || !Array.isArray(users) ){
      setLoading(true);
      getUsers();
    }
    if(users.length !== 0 && Array.isArray(users)){
      setLoading(false);
    }
  },[users, companies]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUsers = () => dispatch(GetUsers(currentUser.token))
  
  const saveUser = (params) => {
    if(Object.keys(user).length === 0){
      dispatch(CreateUser(currentUser.token, params));
    }else{
      dispatch(UpdateUser(user.id, currentUser.token, params))
    }
    
    hideModals();
  }

  const hideModals = () => {
    setShowUsersModal(false);
    setShowDeleteModal(false);
    setUser({});
  }

  const openEdit = (user) => {
    setUser(user);
    setShowUsersModal(true)
  } 

  const findCompany = (companyId) => {
    for(let company of companies){
      if(company.id === companyId){
        return(company.name)
      }
    }
  }

  const deleteUser = (params) => {
    dispatch(DeleteUser(currentUser.token, params.id));
    hideModals();
  }

  const openDelete = (user) => {
    setUser(user);
    setShowDeleteModal(true);
  }
  
  return (
    <Card className='w-25 md-5'>
      {loading === true && 
        <Card.Body className='d-flex justify-content-center'>
          <Spinner size='lg' animation="grow" variant="light"/>
        </Card.Body>
      }
      {loading === false &&
        <Card.Body>
          <h3>Users</h3>
          <br />
          <Button className='btn-dark' onClick={e => setShowUsersModal(true)}>+ New user</Button>
          <br />
          <br />
          {Array.isArray(users) && users.map((user,index) => {return(
            <Card className='light-blue' key={user.email}>
              <Card.Body>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Company:</strong> {!!companies && findCompany(user.company_id)}</p>
                <p><strong>Role:</strong> {roles[user.role]}</p>
                <br />
                {(currentUser.role === 0 || currentUser.role === 1 ) && 
                  <div>
                    <Button className='btn-dark' onClick={e => openEdit(user)}>edit</Button>
                    <Button variant='danger' onClick={e => openDelete(user)}>Delete</Button>
                  </div>
                }
                
              </Card.Body>
            </Card>
          )})}
        </Card.Body>
      }
      <UsersModal showModal={showUsersModal}  hideModal={hideModals} user={user} companies={companies} save={saveUser}/>
      <DeleteModal showModal={showDeleteModal} hideModal={hideModals} remove={deleteUser} element={user} />
    </Card>
  )
}

export default UsersComponent