import React from 'react'
import {Card, Button} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import { shutDownAction, TurnOnAction } from '../../../actions/StartStopAction'

function ActionsComponent({status}) {
  const dispatch = useDispatch();
  
  const turnOnBattery = () => {
    let answer = prompt('please type in password to turn on system');
    if(answer === 'hello'){
      dispatch(TurnOnAction());    
    }else{
      alert('Invalid password')
    }
  }

  const ShutBatteryDown = () => {
    let answer = prompt('Please type in password to shutdown system');
    answer === 'hello' ? dispatch(shutDownAction()) : alert('invalid password');
  }
  
  return (
    <Card>
      <Card.Body>
        <h5>Actions</h5>
        <br />
        <div className='w-100 d-flex justify-content-evenly'>
          {status === 'on' ?  <Button  variant='danger' onClick={e => ShutBatteryDown() }>Shutdown</Button> :  <Button  variant='success' onClick={e => turnOnBattery() }>Turn on</Button>}
          
          <Button>Self test</Button>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ActionsComponent