const UsersReducer = (state = [], action) => {
  switch(action.type){
    case 'GetUsers':
      return state = action.payload;
    case 'CreateUser':
      return state = action.payload;
    case 'UpdateUser':
      return state = action.payload;
    case 'DeleteUser':
      return state = [];
    default:
      return state;
  }
}

export default UsersReducer;