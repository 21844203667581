import React, {useState, useEffect} from 'react';
import {Card, Button, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router';

import SystemsModal from '../../modals/SystemsModal';
import DeleteModal from '../../modals/DeleteModal';

import { GetSystems, CreateSystem, DeleteSystem } from '../../../actions/SystemsAction';
import {CurrentSystem } from '../../../actions/CurrentSysAction';

function SystemsComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSystemsModal, setShowSystemsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentSystem, setCurrentSystem] = useState(null)
  const [loading, setLoading] = useState(true);
  const systems = useSelector(state => state.SystemsReducer);
  const currentUser = useSelector(state => state.SessionReducer);
  const companies = useSelector(state => state.CompaniesReducer);

  useEffect(() => {
    if(systems.length === 0 || !Array.isArray(systems)){
      setLoading(true);
      getSystems();
    }
    if(systems.length !== 0 && Array.isArray(systems)){
      setLoading(false)
    }
  },[systems]) // eslint-disable-line react-hooks/exhaustive-deps

  const getSystems = () => dispatch(GetSystems(currentUser.token, currentUser.company_id))

  const showRemoteSystem = (token) => {
    dispatch(CurrentSystem(token))
    navigate(`/`)
  }

  const saveSystem = (params) => {
    dispatch(CreateSystem(currentUser.token, params));
    hideModal()
  }

  const hideModal = () => {
    setShowSystemsModal(false);
    setShowDeleteModal(false);
    setCurrentSystem(null)
  }

  const findCompany = (companyId) => {
    for(let company of companies){
      if(company.id === companyId){
        return(company.name)
      }
    }
  }

  const openDelete = (system)  => {
    setCurrentSystem(system);
    setShowDeleteModal(true)
  }

  const deleteSystem = (system) => {
    dispatch(DeleteSystem(currentUser.token, system.id))
    hideModal();
  }
  
  return (
    <Card className='d-flex w-75 justify-content-center'>
      {loading === true && 
        <Card.Body className='d-flex justify-content-center'>
          <Spinner size='lg' animation="grow" variant="light"/>
        </Card.Body>
      }
      {loading === false && 
        <Card.Body>
          <h3>Available systems</h3>
          <br/>
          {(currentUser.role === 1 || currentUser.role === 0) && <Button className='btn-dark' onClick={e => setShowSystemsModal(true)}> + New system</Button>}
          <br/>
          <br />
          {systems.map((system, index) => {return(
            <Card key={index} className='light-blue'>
              <Card.Body>
                <h5>{system.name}</h5>
                <br />
                <strong>{system.token}</strong>
                <br />
                <p><strong>Company: </strong> {!!companies && findCompany(system.company_id)}</p>
                <br />
                <br />
                {(currentUser.role === 1 || currentUser.role === 0) && <Button className='btn-dark' onClick={e => showRemoteSystem(system.token)}>Show system</Button>}
                {(currentUser.role === 1 || currentUser.role === 0) && <Button variant='danger' onClick={e => openDelete(system)}>Delete</Button> }
              </Card.Body>
            </Card>
          )})}
        </Card.Body>
      }
       
      <SystemsModal showModal={showSystemsModal} hideModal={hideModal} save={saveSystem} companies={companies}/>
      <DeleteModal showModal={showDeleteModal} hideModal={hideModal} remove={deleteSystem} element={currentSystem} />

    </Card>
  )
}

export default SystemsComponent