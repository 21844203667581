import React from 'react'
import {Card} from 'react-bootstrap'

function ErrorOverviewComponent({errors}) {
  return (
    <Card>
      <Card.Body>
        <h5>Errors</h5>
        <br />
        {errors.map((error, index) => <p key={index}>{error.ErrorMessage}</p>)}
      </Card.Body>
    </Card>
  )
}

export default ErrorOverviewComponent