import { SaveToLocalStorage, LoadCurrentSession, DestroyCurrentSession } from '../scripts/SessionHelper'

const SessionReducer = (state = LoadCurrentSession(), action) => {
  switch(action.type){
    case 'Login':
      if(!!action.payload.token){
        SaveToLocalStorage(action.payload)
      }
      return state =  action.payload;
    case 'Logout':
      DestroyCurrentSession();
      return state = LoadCurrentSession();
    default: 
      return state;
  }
}

export default SessionReducer;