import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Nav, Navbar, Container, Form, Button} from 'react-bootstrap'
import { useNavigate } from 'react-router';

import { SetSearch, ClearSearch } from '../../../actions/searchAction';
import { ResetSystem } from '../../../actions/CurrentSysAction';
import { Logout } from '../../../actions/SessionAction';

function NavbarComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.SessionReducer);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const search = () => {
    if(fromDate && toDate){
      dispatch(SetSearch( fromDate, toDate))
    }
  }

  const clear = () => {
    setFromDate('');
    setToDate('');
    dispatch(ClearSearch());
  }

  const logoutUser = () => {
    navigate('/login')
    dispatch(Logout());
    dispatch(ResetSystem());
    
  }

  return (
    <Navbar >
      <Container>
        <Navbar.Brand href="/dashboard">Ress Admin</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link></Nav.Link>
        </Nav>
      </Container>
      
      <Container className='d-flex justify-content-evenly align-items-center'>
        {(!!currentUser.token && window.location.pathname == '/') &&  // eslint-disable-line react-hooks/exhaustive-deps
          <Nav className='d-flex align-items-center'>
            <Form.Label>From: </Form.Label>
            <Form.Control value={fromDate} onChange={e => setFromDate(e.target.value)} type='date' />
            <Form.Label>To: </Form.Label>
            <Form.Control value={toDate} onChange={e => setToDate(e.target.value)} type='date'/>
            <Button onClick={e => search()}>Search</Button>
            <Button variant='warning' onClick={e => clear()}>Clear</Button>
          </Nav>
        }
        <Nav>
          <Nav.Link onClick={e => logoutUser()}>Logut</Nav.Link>
        </Nav>
      </Container>
      
    </Navbar>
  )
}

export default NavbarComponent