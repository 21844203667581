import React from 'react'

function SoftwareOverviewComponent({software}) {

  return (
    <div className='software-footer'>
      <p>BMS firmware: {software.BmsFW}</p>
      <p>BMS configuration: {software.BmsConfigFile}</p>
      <p>RESS version: {software.RessSwVersion}</p>
      <p>CANbus connected: {software.CaNbusConnectivity ? 'Connected' : 'Disconnected'}</p>
      <p>Internet: {software.InternetConnectivity ? 'Connected' : 'Disconnected'}</p>
    </div>
  )
}

export default SoftwareOverviewComponent