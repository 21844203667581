export const SaveCurrentSystem = (payload) =>{
  try{
    const systemStoragePayload = JSON.stringify(payload);
    sessionStorage.setItem('currentSystem', systemStoragePayload);
  }catch(error){
    console.log(error);
  }
}

export const LoadCurrentSystem = () =>{
  try{
    const systemStoragePayload = sessionStorage.getItem('currentSystem');
    if(systemStoragePayload == null){return null}
    return JSON.parse(systemStoragePayload);
  }catch(error){
    console.log(error);
  }
}

export const DestroyCurrentSystem= () =>{
  try {
    sessionStorage.removeItem('currentSystem');
  } catch (error) {
    console.log(error);
  }
}