export const GetCompanies = (token) => {
  return async(dispatch) =>{
    const companies = await fetch(`${process.env.REACT_APP_CLOUD_URL}companies`, {
      method: 'GET',
      headers: {token}
    })

    dispatch({
      type: 'GetCompanies',
      payload: await companies.json()
    })
  }
}


export const CreateCompany = (token, params) =>{
  return async(dispatch) => {
    const company = await fetch(`${process.env.REACT_APP_CLOUD_URL}companies`,{
      method: 'POST',
      headers: {'Content-type': 'application/json', token},
      body: JSON.stringify({company: params})
    })

    dispatch({
      type: 'CreateCompany',
      payload: await company.json()
    })
  }
}


export const DeleteCompany = (token, id) => {
  return async(dispatch) => {
    await fetch(`${process.env.REACT_APP_CLOUD_URL}companies/${id}`,{
      method: 'DELETE',
      headers: {token}
    })

    dispatch({
      type: 'DeleteCompany'
    })
  }
}