export const Login = (params) => {
  return async(dispatch) => {
      const user = await fetch(`${process.env.REACT_APP_CLOUD_URL}/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
      })
  
      dispatch({
        type: 'Login',
        payload: await user.json()
      })
    }
  } 
  
  export const Logout = () => {
    return ({
      type: 'Logout'
    })
  } 