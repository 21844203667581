export const GetSystems = (token, company) => {
  return async(dispatch) => {
    const systems = await fetch(`${process.env.REACT_APP_CLOUD_URL}systems?company_id=${company}`, {
      method: 'GET',
      headers: {token}
    })

    dispatch({
      type: 'getSystems',
      payload: await systems.json()
    })
  }
}

export const CreateSystem = (token, params) => {
  return async(dispatch) => {
    const system = await fetch(`${process.env.REACT_APP_CLOUD_URL}/systems`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', token: token},
      body: JSON.stringify({system: params})
    })

    dispatch({
      type: 'CreateSystem',
      payload: await system.json()
    })
  }
}

export const DeleteSystem = (token, id) => {
  return async(dispatch) => {
    await fetch(`${process.env.REACT_APP_CLOUD_URL}systems/${id}`,{
      method: 'DELETE',
      headers: {token}
    })

    dispatch({
      type: 'DeleteSystem',
    })
  }
}