import React, {useState, useEffect} from 'react'
import { Button, Modal, Form} from 'react-bootstrap'

function UsersModal({showModal, hideModal, mode, user, companies, save }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState(0);
  const [role, setRole] = useState(0)

  useEffect(() => {
    if(Object.keys(user) !== 0){
      setEmail(user.email);
      setCompany(user.company_id)
    }else{
      setEmail('');
      setCompany('')
    }
  },[user])
 
  
  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{mode === 'new' ? 'New user' : 'Update user'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Label>Email</Form.Label>
            <Form.Control value={email} onChange={e => setEmail(e.target.value)}/>
          </div>
          <br />
          <div>
            <Form.Label>Password</Form.Label>
            <Form.Control value={password} onChange={e => setPassword(e.target.value)} type='password'/>
          </div>
          <br />
          <div>
            <Form.Label>Company</Form.Label>
            <Form.Select value={company} onChange={e => setCompany(e.target.value)}>
              <option value="0">Select company</option>
              {companies.map((company, index) => {return(
                <option key={company.id} value={company.id}>{company.name}</option>
              )})}
            </Form.Select>
            <br />
          </div>
          <div>
            <Form.Label>Role</Form.Label>
            <Form.Select value={role} onChange={e => setRole(e.target.value)}>
              <option value="0">Admin</option>
              <option value="1">Supervisor</option>
              <option value="2">User</option>

            </Form.Select>
            <br />
            <br />
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>Cancel</Button>
          <Button onClick={e => save({email, password, company_id: company, role})}>Save</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default UsersModal