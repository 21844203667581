import {useState } from 'react';
import {Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useSelector } from 'react-redux';
import HomeComponent from './components/HomeComponent';
import ModuleShowComponent from './components/ModuleShowComponent';
import ErrorModal from './components/modals/ErrorModal';
import LoginComponent from './components/LoginComponent';
import DashboardComponent from './components/DashboardComponent';
import NavbarComponent from './components/Widgets/global/NavbarComponent';



function App() { 

  const [errors] = useState([])
  const currentUser = useSelector(state => state.SessionReducer);



  return (
    <div className="App">
      {(!!currentUser && currentUser.role === 0) && <NavbarComponent/>}
      <br />
      <Routes>
        <Route path='/' exact element={<HomeComponent/>} />
        <Route path='/login' exact element={<LoginComponent/>}/>
        <Route path='/dashboard' exact element={<DashboardComponent/>} />
        <Route path='/module/:id' exact element={<ModuleShowComponent/>} />

      </Routes>
      {

        <ErrorModal errors={errors}/>
      }
    </div>
  );
}

export default App;
