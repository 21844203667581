const SystemsReducer = (state = [], action) => {
  switch(action.type){
    case 'getSystems':
      return state = action.payload;
    case 'CreateSystem':
      return state = action.payload;
    case 'DeleteSystem':  
      return state = [];
    default: 
      return state
  }
}

export default SystemsReducer;