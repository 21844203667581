import React, {useState, useEffect} from 'react';
import {Container, Form, Card, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Login } from '../actions/SessionAction';

function LoginComponent() {
	const navigate = useNavigate()
  const dispatch = useDispatch();
	const [errors, setErrors] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const currentUser = useSelector(state => state.SessionReducer)

	useEffect(() => {
		if(!!currentUser && !currentUser.error){
			navigate('/dashboard')
		}else{
			setErrors('Incorect email or password')
		}
	},[currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

	const login = () =>{
		if(email.length === 0 || password.lenght === 0){
			setErrors('Email or password cannot be empty')
		}else{
			const params = {email, password}
			dispatch(Login(params))
		}
	}

	const handleKeypress = (e) => {
		if(e.key === 'Enter'){ login() }
	}
	

	return (
    <Container className='content d-flex align-items-center justify-content-center'>
			<Card className='w-50'>
				<Card.Body className='d-flex flex-column justify-content-center align-items-center'>
					
					<h5>Login</h5>
					
					<br />
					<Form.Control className='w-50 input' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email'/>
					<br />
					<Form.Control className='w-50 input' value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => handleKeypress(e)} placeholder='Password' type='password'/>
					<br />
					{errors.length !== 0 && <p style={{color: 'red'}}>{errors}</p>}
					<br />
					<Button onClick={e => login()}>Login</Button>
				</Card.Body>
			</Card>
    </Container>
  )
}

export default LoginComponent