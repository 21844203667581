import React from 'react'
import { Card } from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function PowerOverviewComponent({system}) {
  return (
    <Card className='w-100'>
			<Card.Body>
				<h5>Power overview </h5>
        <br />
        {(!Array.isArray(system.DischargePower) && !Array.isArray(system.chargePower) && !Array.isArray(system.SystemVoltage)) ? 
				  <div className='d-flex justify-content-evenly'>
          
            <div> 
              <p style={{fontSize: '40px'}}>{(system.DischargePower).toFixed(2)} </p>
              <p>Discharge (KW)</p>
            </div>

            <div> 
              <p style={{fontSize: '40px'}}>{(system.ChargePower).toFixed(2)} </p>
              <p>Charge (KW)</p>
            </div>

          <div> 
            <p style={{fontSize: '40px'}}>{system.Current} </p>
            <p>Current (A)</p>
          </div>

            <div> 
              <p style={{fontSize: '40px'}}>{system.SystemVoltage} </p>
              <p>System voltage (V)</p>
            </div>
         
				  </div>
          :
          <Line className='diagram'
            data={
              {
                labels: system.SystemVoltage.map(voltage => voltage.timestamp.split('T')[0].split('.')[0]),              
                datasets: [
                  {
                    label: 'Charge power',
                    data: system.ChargePower.map(charge => charge.chargePower),
                    pointRadius: 0,
                    borderColor: '#de2d26',
                    backgroundColor: '#de2d26'
                  },
                  {
                    label: 'Discharge power',
                    data: system.DischargePower.map(discharge => discharge.dischargePower),
                    pointRadius: 0,
                    borderColor: '#2b8cbe',
                    backgroundColor: '#2b8cbe'
                  },
                  {
                    label: 'Voltage',
                    data: system.SystemVoltage.map(voltage => voltage.systemVoltage),
                    pointRadius: 0,
                    borderColor: '#31a354',
                    backgroundColor: '#31a354'
                  },
                  {
                    label: 'Current',
                    data: system.Current.map(current => current.current),
                    pointRadius: 0,
                    borderColor: 'pink',
                    backgroundColor: 'pink'
                  }
                ],
              }
            }

            options={{
              animation: {
                duration: 0
              },
              plugins: {
                legend: {
                  labels: {
                    text: 'test',
                    color: '#FFFFFF'
                  }
                }
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'MV',
                    color: 'white'
                  },
                  display: true,
                  
                  
                  grid: {
                    color: '#005a85',
                  },
                  ticks:{
                    color: 'white'
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: 'Time',
                    color: 'white'
                  },
                  ticks: {
                    color: 'white'
                  }
                }
              }
            }}
          />
        }
			</Card.Body>
    </Card>
  )
}

export default PowerOverviewComponent