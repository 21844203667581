import React, {useEffect, useState} from 'react'
import { Card } from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

;

function TemperatureOverviewComponent({temperature}) {

  const [avg, setAvg] = useState([]);
  const [min, setMin] = useState([]);
  const [max, setMax] = useState([]);
  const [timestamps, setTimestamps] = useState([]);


  useEffect(() => {
    setValues(temperature)
  },[temperature])


  const setValues = (temps) => {
    const stamp = [];
    const avgTemp = [];
    const minTemp = [];
    const maxTemp = [];
    if(!!temps){
      for ( let temp of temps){
        avgTemp.push(temp.CellTempAvg);
        minTemp.push(temp.CellTempMin);
        maxTemp.push(temp.CellTempMax);
        stamp.push(temp.CreatedTimestamp.split('T')[1].split('.')[0])
      }
    }
    
    setTimestamps(stamp);
    setAvg(avgTemp);
    setMin(minTemp);
    setMax(maxTemp);
  }

  return (
    <Card className='diagram-card'>
      <Card.Body>
        <h5>Temperature Overview</h5>
        <br />
        <Line className='diagram'
            data={
              {
                labels: timestamps,
                datasets: [
                  {
                    label: 'Average temperature',
                    data: avg,
                    pointRadius: 0,
                    borderColor: 'red',
                    backgroundColor: 'red',
                  },
                  
                  {
                    label: 'Min temperature',
                    data: min,
                    pointRadius: 0,
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  {
                    label: 'Max temperature',
                    data: max,
                    pointRadius: 0,
                    borderColor: '#2b8cbe',
                    backgroundColor: '#2b8cbe',
                  },
                ],
              }
            }

            options={{
              animation: {
                duration: 0
              },
              plugins: {
                legend: {
                  labels: {
                    color: '#FFFFFF'
                  }
                }
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: '°C',
                    color: 'white'
                  },
                  display: true,
                  suggestedMin: 0,
                  grid: {
                    color: '#005a85',
                  },
                  ticks:{
                    color: 'white',
                    
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: 'Time',
                    color: 'white'
                  },
                  ticks: {
                    color: 'white'
                  }
                }
              }
            }}
          />
      </Card.Body>
    </Card>
  )
}

export default TemperatureOverviewComponent