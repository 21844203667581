import React from 'react'
import { Modal} from 'react-bootstrap'

function ErrorModal({errors}) {
  
  return (
    <Modal show={errors.length !== 0} onHide={errors.length === 0 }>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">{errors}</div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
  )
}

export default ErrorModal