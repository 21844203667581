import React, {useEffect, useState} from 'react'
import { Card } from 'react-bootstrap'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


function VoltageOverviewComponent({voltage}) {
  const [cellVoltageMax, setCellVoltageMax] = useState([])
  const [cellVoltageMin, setCellVoltageMin] = useState([])
  const [cellVoltageAvg, setCellVoltageAvg] = useState([])
  const [timeStamps, setTimeStamps] = useState([])

  useEffect(() => {
    addToState(voltage)
  },[voltage])

  const addToState = (objects) => {

    let pVolt  = [],
        maxVolt  = [],
        minVolt= [],
        avgVolt = [],
        difVolt = [],
        stamp = [];
    if(Array.isArray(objects)){
      for(let object of objects ){
        pVolt.push(object.PackVoltage);
        maxVolt.push(object.CellVoltageMax);
        minVolt.push(object.CellVoltageMin);
        avgVolt.push(object.CellVoltageAvg);
        difVolt.push(object.CellVoltageDiff);
        stamp.push(object.CreatedTimestamp.split('T')[1].split('.')[0])
      }
    }
    
    setTimeStamps(stamp)
    setCellVoltageAvg(avgVolt);
    setCellVoltageMin(minVolt);
    setCellVoltageMax(maxVolt);
  }

  return (
    <Card className='diagram-card'>
      <Card.Body> 
        <h5>Voltage overview</h5>
        <br />
        <Line className='diagram'
          data={
            {
              labels: timeStamps,              
              datasets: [
                {
                  label: 'Average voltage',
                  data: cellVoltageAvg,
                  pointRadius: 0,
                  borderColor: '#de2d26',
                  backgroundColor: '#de2d26'
                },
                {
                  label: 'Max. cell voltage',
                  data: cellVoltageMax,
                  pointRadius: 0,
                  borderColor: '#2b8cbe',
                  backgroundColor: '#2b8cbe'
                },
                {
                  label: 'Min. cell voltage',
                  data: cellVoltageMin,
                  pointRadius: 0,
                  borderColor: '#31a354',
                  backgroundColor: '#31a354'
                }
              ],
            }
          }

          options={{
            animation: {
              duration: 0
            },
            plugins: {
              legend: {
                labels: {
                  text: 'test',
                  color: '#FFFFFF'
                }
              }
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'MV',
                  color: 'white'
                },
                display: true,
                
                
                grid: {
                  color: '#005a85',
                },
                ticks:{
                  color: 'white'
                }
              },
              x: {
                title: {
                  display: true,
                  text: 'Time',
                  color: 'white'
                },
                ticks: {
                  color: 'white'
                }
              }
            }
          }}
        />
      </Card.Body>
    </Card>
    
  )
}

export default VoltageOverviewComponent