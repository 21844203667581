import React, {useEffect, useState} from 'react'
import { Container, Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import CurrentOverviewComponent from './Widgets/home/TemperatureOverviewComponent'
import ModuleOverviewComponent from './Widgets/home/ModuleOverviewComponent'
import SoftwareOverviewComponent from './Widgets/home/SoftwareOverviewComponent'
import SystemOverviewComponent from './Widgets/home/SystemOverviewComponent'
import ErrorOverviewComponent from './Widgets/home/ErrorOverviewComponent'
import VoltageOverviewComponent from './Widgets/home/VoltageOverviewComponent'
import GpioOverviewCOmponent from './Widgets/home/GpioOverviewCOmponent'
import PowerOverviewComponent from './Widgets/home/PowerOverviewComponent'
import ActionsComponent from './Widgets/home/ActionsComponent'

import { getAll } from '../actions/GetAllAction'
import { GetSystemData } from '../actions/SystemDataAction'

function HomeComponent() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const local = useSelector(state => state.GetALLReducer);
  const cloud = useSelector(state =>  state.SystemDataReducer);
  const currentUser = useSelector(state => state.SessionReducer);
  const currentSystem = useSelector(state => state.CurrentSysReducer);
  const searchParams = useSelector(state => state.SearchReducer);

  useEffect(() => {
    const interval = setInterval(() => {
      if(!!currentUser && !!currentSystem){
        dispatch(GetSystemData(currentUser.token, currentSystem, searchParams))
      }else{
        dispatch(getAll());
      }
    }, 5000)
    return () => clearInterval(interval);
  },[dispatch, currentSystem, currentUser, searchParams])

  useEffect(() => {
    !!currentUser && !!currentSystem ? setData(cloud) : setData(local);
  }, [local, cloud, currentUser, currentSystem])

  useEffect(() => {
    if(Object.keys(data).length !== 0){setLoading(false)}
  }, [data])

  return (
    <Container fluid>
    {loading === true && 
      <Container className='d-flex justify-content-center'>
        <Spinner size='lg' animation="grow" variant="light"/>
      </Container>
    }
    {loading === false && 
      <div className='d-flex'>
        <div className='w-100 md-5'>
          { (data['Voltage_dtos']) && <VoltageOverviewComponent voltage={data['Voltage_dtos']}/>}

          { (data.Temperature_dtos) &&<CurrentOverviewComponent temperature={!!data && data.Temperature_dtos}/>}
        <div className='d-flex justify-content-evenly'>
          
          {(data.SystemOverview_dto) && <PowerOverviewComponent system={!!data && data.SystemOverview_dto}/>}
        </div>

        <div >
          {(data.SystemOverview_dto) && <SystemOverviewComponent system={!!data && data.SystemOverview_dto}/>}
          
        </div>
        </div>

        <div className='w-100 md-5'>
          {(data.Gpio_dtos) && <GpioOverviewCOmponent gpio={!!data && data.Gpio_dtos.reverse()}/>}
          {(!!data.ErrorCode_dto && data.ErrorCode_dto.ErrorCodes) && <ErrorOverviewComponent errors={!!data && data.ErrorCode_dto.ErrorCodes}/>}
          <ActionsComponent/>
        </div>

        <div className='w-100 md-5'>
          {(data.Module_dto) && <ModuleOverviewComponent module={!!data && data.Module_dto}/>}
        </div>
      </div>
    }
      <div >
        <br />
        <br />
        {(loading === false && (data.Software_dto)) && <SoftwareOverviewComponent software={!!data && data.Software_dto}/>}
      </div>
    </Container> 
    
  )
}

export default HomeComponent