const CompaniesReducer = (state = [], action) =>{
  switch(action.type){
    case 'GetCompanies':
      return state = action.payload;
    case 'CreateCompany':
      return state = action.payload;
    case 'DeleteCompany':
      return state = []
    default:
      return state;
  }
}

export default CompaniesReducer;