import React from 'react'
import { Button, Modal} from 'react-bootstrap'

function DeleteModal({showModal, hideModal, remove, element}) {
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{'Confirm delete'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{color: 'black'}}>Are you sure you want to delete <strong style={{color: 'black'}}>{!!element && element.name}</strong>  ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>Cancel</Button>
        <Button variant='danger' onClick={e => remove(element)}>Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal