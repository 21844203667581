import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {Container, Card, Spinner} from 'react-bootstrap';
import { getAll } from '../actions/GetAllAction';
import { GetSystemData } from '../actions/SystemDataAction';
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

function ModuleShowComponent() {
  const dispatch = useDispatch();
  const params = useParams();
  const local = useSelector(state => state.GetALLReducer);
  const cloud = useSelector(state =>  state.SystemDataReducer);
  const [module, setModule] = useState([]);
  const currentUser = useSelector(state => state.SessionReducer);
  const currentSystem = useSelector(state => state.CurrentSysReducer);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if(!!currentUser && !!currentSystem){
        dispatch(GetSystemData(currentUser.token, currentSystem, {}))
      }else{
        dispatch(getAll());
      }
    }, 5000)
    return () => clearInterval(interval);
  }, [dispatch, currentSystem, currentUser])

  useEffect(() => {
    if(Object.keys(cloud).length !== 0){
      findModule(cloud['ModuleCells_dtos']);
    }else{
      findModule(local['ModuleCells_dtos']);
    }
  }, [cloud, local])

  useEffect(() => {
    console.log(module)
    if(module.length !== 0){
      setLoading(false)
    }
  },[module])
  
  const findModule = (modules) => {
    for(let module of modules){
      console.log(module['ModuleId'] == params.id)
      if(module['ModuleId'] == params.id){
        setModule(module);
        break;
      }
    }
  }

  return (
    <Container>
      <br />
      <Link to='/'><span className="material-symbols-outlined" style={{color: 'white'}}>arrow_back</span></Link>
      <br />
      <br />
      <Card className='w-100 d-flex align-items-center justify-content-center '>
       <h2> Module {module.ModuleId}</h2>
       {loading === true  && <Spinner animation="grow" variant="light" />}
       <br />
       <div className='w-100 d-flex flex-wrap justify-content-evenly'>
        {!!module && module.CMUs.map((cmu) => {
          return(
            <Card key={`cmu ${cmu.cmuId}`} className=' light-blue' style={{width: '30%'}}>
              <br />
              <h4>Cmu {cmu.CmuId}</h4>
              <Card.Body className='d-flex flex-wrap  justify-content-evenly'>
                {cmu.Cells.map((cell) => {
                  return(
                    <Card key={cell.CellId} className='w-100'>
                      <br />
                      <h6>Cell {cell.CellId}</h6>
                      <Card.Body className='d-flex justify-content-evenly'> 
                        <div style={{position: 'relative'}}>
                          <Doughnut className='doughnut'
                            data={{
                              datasets: [{
                                data: [cell.CellVoltage, (5000 - cell.CellVoltage)],
                                backgroundColor: [ "#006d35", "#005a85" ],
                                display: true,
                                borderColor: "#005a85"
                              }]
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false
                                },
                                tooltip: {
                                  enabled: false
                                }
                              },
                              rotation: -90,
                              circumference: 180,
                              cutout: "60%",
                              maintainAspectRatio: true,
                              responsive: true
                            }}
                          />
                          <div style={{ position: "absolute", top: "55%", left: "40%", transform: "translate(-50%, -50%)", textAlign: "center" }} >
                            <p>{parseInt((cell.CellVoltage))}</p>
                            
                          </div>
                          <div style={{position: 'relative', left: '-9%'}}><p>Cell voltage (mV)</p></div>
                        </div>
                        <div style={{position: 'relative'}}>
                          <Doughnut className='doughnut'
                            data={{
                              datasets: [{
                                data: [cell.CellTemp, (121 - cell.CellTemp)],
                                backgroundColor: ["#830007", "#005a85"],
                                display: true,
                                borderColor: "#005a85"
                              }]
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false
                                },
                                tooltip: {
                                  enabled: false
                                }
                              },
                              rotation: -90,
                              circumference: 180,
                              cutout: "60%",
                              maintainAspectRatio: true,
                              responsive: true
                            }}
                          />
                          <div style={{ position: "absolute", top: "55%", left: "49%", transform: "translate(-50%, -50%)", textAlign: "center" }} > <p>{cell.CellTemp}</p></div>
                          <div><p>Cell Temp ( °C )</p></div>
                        </div>
                      </Card.Body>
                    </Card>
                  )
                })} 
              </Card.Body>                
            </Card>
          )
        })}
       </div>
       <br />
      </Card> 
      <br />
    </Container>
  )
}

export default ModuleShowComponent