import React, {useState} from 'react'
import { Button, Modal, Form} from 'react-bootstrap'

function CompaniesModal({showModal, hideModal, save}) {
  const [name, setName] = useState('')
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{'New company'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>name</Form.Label>
        <Form.Control value={name} onChange={e => setName(e.target.value)}/>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>Cancel</Button>
        <Button onClick={e => save({name})}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CompaniesModal