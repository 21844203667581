import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router';

import UsersComponent from './Widgets/dashboard/UsersComponent';
import SystemsComponent from './Widgets/dashboard/SystemsComponent';
import CompaniesComponent from './Widgets/dashboard/CompaniesComponent';





function DashboardComponent() {
  const navigate = useNavigate();

  const currentUser = useSelector(state => state.SessionReducer)

  useEffect(() => {
    if(!currentUser){navigate('/login')}
  },[currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className='d-flex justify-content-center'>
      <br />
      <UsersComponent/>
      
      <SystemsComponent/>
      
      <CompaniesComponent/>
      
    </Container>
  )
}

export default DashboardComponent