import {combineReducers} from 'redux';
import ModuleReducer from './ModuleReducer';
import GetALLReducer from './GetAllReducer';
import StartStopReducer from './StartStopReducer';
import SessionReducer from './SessionReducer';
import SystemsReducer from './SystemsReducer';
import SystemDataReducer from './SystemDataReducer';
import CurrentSysReducer from './CurrentSysReducer';
import UsersReducer from './UsersReducer';
import CompaniesReducer from './CompaniesReducer';
import SearchReducer from './SearchReducer';


const allReducers = combineReducers({
  ModuleReducer,
  GetALLReducer, 
  StartStopReducer, 
  SessionReducer,
  SystemsReducer,
  SystemDataReducer,
  CurrentSysReducer,
  UsersReducer,
  CompaniesReducer,
  SearchReducer,
})

export default allReducers;