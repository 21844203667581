export const GetSystemData = (token, systemToken, searchParams) => {
  let searchString = '?';

  for(let key of Object.keys(searchParams)){
    searchString = searchString + `${key}=${searchParams[key]}&`;
  }

  return async(dispatch) =>{
    const data = await fetch(`${process.env.REACT_APP_CLOUD_URL}system_data${searchString}`,{
      method: 'GET',
      headers: {token, 'system-token': systemToken}
    })

    dispatch({
      type: 'GetSystemData',
      payload: await data.json()
    })
  }
}