export const getAll = (id) => {
    return async(dispatch) => {
      const module = await fetch(`${process.env.REACT_APP_API_URL}/Battery/GetAll`, {
        method: 'GET'
      })
  
      dispatch({
        type: 'getAll',
        payload: await module.json()
      })
    }
  }