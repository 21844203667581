export const shutDownAction = (id) => {
  return async(dispatch) => {
    const module = await fetch(`${process.env.REACT_APP_API_URL}/Battery/stop`, {
      method: 'GET'
    })

    dispatch({
      type: 'shutDown',
      payload: await module.json()
    })
  }
}

export const TurnOnAction = (id) => {
  return async(dispatch) => {
      const module = await fetch(`${process.env.REACT_APP_API_URL}/Battery/start`, {
      method: 'GET'
      })

      dispatch({
      type: 'turnOn',
      payload: await module.json()
      })
  }
}