import React from 'react'
import {useNavigate} from 'react-router';
import { Card } from 'react-bootstrap'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);


function ModuleOverviewComponent({module}) {
  const navigate = useNavigate()
  return (
    <div>
      <Card>
        <Card.Body> 
          <h5>Module</h5>
          <br />
          <div className='w-100 d-flex justify-content-evenly' >
            <div>
              <h2>{module.Length}</h2>
              <p>Number of modules</p>
            </div>
            <div>
              <h2>{!!module && module.length * module[0]['CmuEndId']}</h2>
              <p>Number of CMU's</p>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div style={{overflowY: 'scroll'}}>
        {(!!module && module.length !== 0) && module.map((battery, index) => {
          return(
            <Card key={index} style={{cursor: 'pointer'}} onClick={e => navigate(`/module/${battery.Id}`)}>
              <Card.Body>
              <h5>Module {battery.Id}</h5>
              <br />
              <div className='d-flex justify-content-evenly'>
                <div>
                  <Doughnut className='half-doughnut'
                    data={{
                      datasets: [{
                        data: [battery.ModuleAverageCellTemp, (battery.ModuleMaxCellTemp - battery.ModuleAverageCellTemp)],
                        backgroundColor: ["#64bcd8", "#005a85"],
                        display: true,
                        borderColor: "#005a85"
                      }]
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          enabled: false
                        }
                      },
                      rotation: -90,
                      circumference: 180,
                      cutout: "60%",
                      maintainAspectRatio: true,
                      responsive: true
                    }}
                  />
                  <div style={{ position: "absolute", top: "65%", left: "31%", transform: "translate(-50%, -50%)", textAlign: "center" }} >
                    <p>{(battery.ModuleAverageCellTemp).toFixed(0)} °C</p>
                    <p>Average</p>
                  </div>
                  <div className='w-100 d-flex justify-content-between'>
                    <div>
                      <p>{battery.ModuleMinCellTemp} °C</p>
                      <p>Min</p>
                    </div>
                    <div>
                      <p>{battery.ModuleMaxCellTemp} °C</p>
                      <p>Max</p>
                    </div>
                  </div>
                </div>
                <div>
                  <Doughnut className='half-doughnut'
                    data={{
                      datasets: [{
                        data: [battery.ModuleAverageCellVoltage, (battery.moduleMaxCellVoltage - battery.ModuleAverageCellVoltage)],
                        backgroundColor: ["#00a450", "#005a85"],
                        display: true,
                        borderColor: "#005a85"
                      }]
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          enabled: false
                        }
                      },
                      rotation: -90,
                      circumference: 180,
                      cutout: "60%",
                      maintainAspectRatio: true,
                      responsive: true
                    }}
                  />
                  <div style={{ position: "absolute", top: "65%", left: "69%", transform: "translate(-50%, -50%)", textAlign: "center" }} >
                    <p>{(battery.ModuleAverageCellVoltage).toFixed(0)} </p>
                    <p>Average mV</p>
                  </div>
                  <div className='w-100 d-flex justify-content-between' style={{width: '55%', }}>
                    <div>
                      <p>{parseInt(battery.ModuleMinCellVoltage)} mV</p>
                      <p>Min </p>
                    </div>
                    <div>
                      <p>{parseInt(battery.ModuleMaxCellVoltage)} mV</p>
                      <p>Max</p>
                    </div>
                  </div>
                </div>
              </div>
              </Card.Body>
            </Card>
          )
        })}
      </div>
    </div>
   
  )
}

export default ModuleOverviewComponent