import React, {useEffect, useState} from 'react'
import { Card} from 'react-bootstrap'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

function SystemOverviewComponent({system}) {
  // const [chargeContactor, setChargeContactor] = useState(false)
  // const [chargePower, setChargePower] = useState(0)
  const [cycleCount, setCycleCount] = useState(0)
  // const [dischargeContactor, setDischargeContactor] = useState(false)
  // const [dischargePower, setDischargePower] = useState(0)
  // const [prechargeContactor, setPrechargeContactor] = useState(false)
  const [soc, setSoc] = useState(0)
  const [soh, setSoh] = useState(0)
  const [sop, setSop] = useState(0)
  const [uptime, setUptime] = useState(0)

  useEffect(() => {
    // if(!!system.chargeContactor){setChargeContactor(system.chargeContactor)}
    // if(!!system.chargePower){setChargePower(system.chargePower)}
    if(!!system.CycleCount){setCycleCount(system.CycleCount)}
    // if(!!system.dischargeContactor){setDischargeContactor(system.dischargeContactor)}
    // if(!!system.dischargePower){setDischargePower(system.dischargePower)}
    // if(!!system.prechargeContactor){setPrechargeContactor(system.prechargeContactor)}
    if(!!system.Soc){setSoc(system.Soc)}
    if(!!system.Soh){setSoh(system.Soh)}
    if(!!system.Sop){setSop(system.Sop)}
    if(!!system.Uptime){setUptime(system.Uptime)}
  },[system])

  return (
    <Card className='card'>
      <Card.Body>
        <h5>System overview</h5>
		<br />
        <div className='d-flex justify-content-between'>
          <div style={{position: 'relative'}}>
						<Doughnut className='doughnut'
							data={{
								datasets: [{
									data: [soc, (100 - soc)],
									backgroundColor: ['green', "#005a85"],
									display: true,
									borderColor: "#005a85"
								}]
							}}
							options={{
								plugins: {
									legend: {
										display: false
									},
									tooltip: {
										enabled: false
									}
								},
								rotation: -0,
								circumference: 360,
								cutout: "60%",
								maintainAspectRatio: true,
								responsive: true
							}}
						/>
						<div style={{ position: "absolute", top: "40%", left: "51%", transform: "translate(-50%, -50%)", textAlign: "center" }} > <p>{soc} %</p></div>
            <div><p>SOC</p></div>
          </div>

          <div style={{position: 'relative'}}>
						<Doughnut className='doughnut'
							data={{
								datasets: [{
									data: [soh, (100 - soh)],
									backgroundColor: ["#830007", "#005a85"],
									display: true,
									borderColor: "#005a85"
								}]
							}}
							options={{
								plugins: {
									legend: {
										display: false
									},
									tooltip: {
										enabled: false
									}
								},
								rotation: -90,
								circumference: 360,
								cutout: "60%",
								maintainAspectRatio: true,
								responsive: true
							}}
						/>
						<div style={{ position: "absolute", top: "40%", left: "51%", transform: "translate(-50%, -50%)", textAlign: "center" }} > <p>{sop} %</p></div>
            <div><p>SOH</p></div>
          </div>

          <div style={{position: 'relative'}}>
						<Doughnut className='doughnut'
							data={{
								datasets: [{
									data: [sop, (100 - sop)],
									backgroundColor: ["#006d35", "#005a85"],
									display: true,
									borderColor: "#005a85"
								}]
							}}
							options={{
								plugins: {
									legend: {
										display: false
									},
									tooltip: {
										enabled: false
									}
								},
								rotation: -90,
								circumference: 360,
								cutout: "60%",
								maintainAspectRatio: true,
								responsive: true
							}}
						/>
						<div style={{ position: "absolute", top: "40%", left: "51%", transform: "translate(-50%, -50%)", textAlign: "center" }} > <p>{sop} %</p></div>
            <div><p>SOP</p></div>
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center' >
						
						<div> <p style={{fontSize: '50px'}}>{cycleCount}</p></div>
            <div>
              <p>Cycles</p>
            </div>
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center'>
						
						<div  > <p style={{fontSize: '50px'}}>{uptime}</p></div>
            <div>
              <p>Uptime (h)</p>
            </div>
          </div>
        </div>

        <br />
      
      </Card.Body>
    </Card>


  )
}

export default SystemOverviewComponent