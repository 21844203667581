export const GetUsers = (token) => {
  
  return async(dispatch) => {
    const users = await fetch(`${process.env.REACT_APP_CLOUD_URL}users`, {
      method: 'GET',
      headers: {token}
    })

    dispatch({
      type: 'GetUsers',
      payload: await users.json()
    })
  }
}

export const CreateUser = (token, params) => {
  return async(dispatch) => {
    const user = await fetch(`${process.env.REACT_APP_CLOUD_URL}users`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', token: token}, 
      body: JSON.stringify({user: params})
    })

    dispatch({
      type: 'CreateUser',
      payload: user.json()
    })
  }
}

export const UpdateUser = (id, token, params) => {
  return async(dispatch) => {
    const user = await fetch(`${process.env.REACT_APP_CLOUD_URL}/users/${id}`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', token: token}, 
      body: JSON.stringify({user: params})
    })

    dispatch({
      type: 'UpdateUser',
      payload: await user.json()
    })
  }
}

export const DeleteUser = (token, id) => {
  return async(dispatch) => {
    await fetch(`${process.env.REACT_APP_CLOUD_URL}users/${id}`, {
      method: 'DELETE',
      headers: {token}
    }) 

    dispatch({
      type: 'DeleteUser',
    })
  }
}