import { SaveCurrentSystem, LoadCurrentSystem, DestroyCurrentSystem } from "../scripts/CurrentSystemHelper";

const CurrentSysReducer = (state = LoadCurrentSystem(), action) => {
  switch(action.type){
    case 'CurrentSystem':
      SaveCurrentSystem(action.payload);
      return state = action.payload;
    case 'ResetSystem':
      DestroyCurrentSystem();
    break
    default:
      return state
  }
}

export default CurrentSysReducer;