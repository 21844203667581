import React, {useEffect, useState} from 'react'
import {Card, Button, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import { GetCompanies, CreateCompany, DeleteCompany} from '../../../actions/CompaniesAction';
import CompaniesModal from '../../modals/CompaniesModal';
import DeleteModal from '../../modals/DeleteModal';

function CompaniesComponent() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [loading, setLoading] = useState(true)
  const companies = useSelector(state => state.CompaniesReducer)
  const currentUser = useSelector(state => state.SessionReducer)

  useEffect(() => {
    if(companies.length === 0 || !Array.isArray(companies) ){
      setLoading(true);
      getCompanies();
    }
    if(companies.length !== 0 && Array.isArray(companies) ){
      setLoading(false);
    }
  },[companies]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanies = () => dispatch(GetCompanies(currentUser.token))

  const saveCompany = (params) => {
    dispatch(CreateCompany(currentUser.token, params))
    hideModal();
  }

  const hideModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setCurrentCompany(null);
  }

  const openDelete = (company) => {
    setCurrentCompany(company);
    setShowDeleteModal(true);
  }

  const deleteCompany = (company) => {
    dispatch(DeleteCompany(currentUser.token, company.id));
    hideModal();
  }

  return (
    <Card className='w-25'>
      {loading === true && 
        <Card.Body className='d-flex justify-content-center'>
          <Spinner size='lg' animation="grow" variant="light"/>
        </Card.Body>
      }
      {loading === false && 
        <Card.Body>
          <h3>Companies</h3>
          <br />
          
          {(currentUser.role === 1 || currentUser.role === 0) && <Button className='btn-dark' onClick={e => setShowModal(true)}> + Add company</Button>}
          <br /> 
          <br />
          {!!companies && companies.map((company, index )=> {return(
            <Card  className='light-blue' key={index}>
              <Card.Body className='d-flex justify-content-between'>
                
                <p><strong>Name:</strong> {company.name}</p>
                {(currentUser.role === 1 || currentUser.role === 0) && <Button variant='danger' onClick={e => openDelete(company)}>Delete</Button>}
                

              </Card.Body>
            </Card>
          )})}
        </Card.Body>
      }
      
      <CompaniesModal showModal={showModal} hideModal={hideModal} save={saveCompany} />
      <DeleteModal showModal={showDeleteModal} hideModal={hideModal} remove={deleteCompany}  element={currentCompany} />
    </Card>
  )
}

export default CompaniesComponent