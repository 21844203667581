const StartStopReducer = (state = '', action) => {
  switch(action.type){
    case 'shutDown':
      return state = action.payload;
    case 'turnOn':
      return state = action.payload;
    default:
      return state; 
  }
}

export default StartStopReducer;